import './App.css';
import { useEffect } from 'react';

function App() {
  const text = ['Yes', 'Very', 'Totally', 'Extremely', 'Duh', '10/10'];

  const getText = (text) => {
    return text[Math.floor(Math.random() * text.length)];
  }

  useEffect(() => {
    document.title = "Is Diego Luna hot?";
  }, []);

  return (
    <div className="App">
      <body className="App-body">
        <a className="text" 
        href='https://www.google.com/search?q=diego+luna&tbm=isch'
        >{getText(text)}
        </a>
      </body>
    </div>
  );
}

export default App;
